import React, { useState } from "react";

const DateInput = ({ name, prevDate }) => {
  const [date, setDate] = useState(prevDate);

  const formatDate = (input) => {
    // Remove any non-digit characters
    let value = input.replace(/\D/g, '');

    // Check if the length is valid for a date
    if (input.length > 7) {
      value =
      value.substring(0, 4) +
      "-" +
      value.substring(4, 6) +
      "-" +
      value.substring(6, 8);
    }
    else if (input.length > 4) {
      value =
        value.substring(0, 4) +
        "-" +
        value.substring(4, 6)
    }

    setDate(value);
  };

  return (
    <input
      type="text"
      name={name}
      value={date}
      onChange={({ target }) => formatDate(target.value)}
      placeholder="aaaa-mm-dd"
      minLength="10"
      maxlength="10"
    />
  );
};

export default DateInput;
